import React from 'react';

import type { Error } from 'types/Error';
import type { FeatureFlags } from 'types/FeatureFlags';
import type { Payment } from 'types/Payment';

import { isPaypalAppconnectDeclinedOrBlocked } from 'businessLogic/Payment/Common';
import MessageCard from 'components/Shared/MessageCard/MessageCard';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import {
  gettoLocaleTimeString,
  getNanopayBlockDueDate,
  isNanoPayBankBlock,
  getPayPalAppConnectBlockDueDate,
  isPayPalAppConnectBlock,
} from 'shared/clientUtils';
import { TXN_MAP } from 'types/constants';

const splunkReporter = SplunkReporter.getInstance();

export const getPaymentErrorMessage = ({
  paymentStatus,
  paymentError,
  error,
  companyName,
  featureFlags,
  paymentMethodType,
  payPalProcessor,
  logger,
  isAutoPayOn,
  isDateScheduled,
}: {
  error?: Error;
  paymentStatus: Payment['paymentStatus'];
  paymentError?: Payment['paymentError'];
  companyName: string;
  featureFlags: FeatureFlags;
  paymentMethodType: Payment['paymentMethodType'];
  payPalProcessor: Payment['payPalProcessor'];
  logger: string;
  isAutoPayOn: Payment['isAutoPayOn'];
  isDateScheduled: Payment['isDateScheduled'];
}) => {
  let header, text: string | undefined;
  let status: 'error' | 'info' | 'warn' = 'error';
  let messageValues: Record<string, string> | undefined;
  if (paymentError && paymentError.message && paymentStatus === TXN_MAP.STATUS.DECLINED) {
    header = `PAYFLOW_${paymentStatus}_ERROR_HEADER`;
    text = `PAYFLOW_${paymentError.message}_ERROR_TEXT`;
  } else if (featureFlags && featureFlags['block-payments']) {
    header = 'BLOCK_PAYMENTS_HEADER';
    text = 'BLOCK_PAYMENTS_TEXT';
  } else if (
    paymentMethodType === 'pp' &&
    isPaypalAppconnectDeclinedOrBlocked(featureFlags, paymentStatus, payPalProcessor)
  ) {
    header = 'PAYFLOW_PAYPAL_APPCONNECT_ERROR_HEADER';
    text = 'PAYFLOW_PAYPAL_APPCONNECT_ERROR_MESSAGE';
    status = 'warn';
  } else if (error && error.title) {
    header = error.title;
    text = error.body;
    messageValues = error.i18nProps;
  } else if (
    paymentError &&
    paymentError.message &&
    paymentError.message === 'PAYFLOW_VALIDATION_CARD_TYPE_INVALID'
  ) {
    text = paymentError.message;
    messageValues = paymentError;
  } else if (paymentError && paymentError.message) {
    header = `PAYFLOW_${paymentError.message}_HEADER`;
    text = `PAYFLOW_${paymentError.message}_MESSAGE`;
    messageValues = { 0: companyName };
  } else if (isPayPalAppConnectBlock(paymentMethodType, payPalProcessor, featureFlags)) {
    header = 'BLOCK_PAYMENTS_PAYPAL_HEADER';
    text = 'BLOCK_PAYMENTS_PAYPAL_TEXT';

    // get due date
    const blockingDueDate = getPayPalAppConnectBlockDueDate(featureFlags);
    if (blockingDueDate > 0) {
      header = 'BLOCK_PAYMENTS_PAYPAL_HEADER_DUE_DATE';
      text = 'BLOCK_PAYMENTS_DUE_DATE';
      const localeTimeString = gettoLocaleTimeString(blockingDueDate);
      messageValues = { dueTime: localeTimeString };

      splunkReporter.contextual({
        logInfo: { logLevel: 'info', logger },
        event: 'viewSale',
        action: 'render',
        activityInfo: {
          isBlockWithDueDate: true,
          blockingDueDate,
          localeTimeString,
          source: 'PayPal Appconnect',
        },
      });
    }

    // block bank nanopay
  } else if (isNanoPayBankBlock(paymentMethodType, featureFlags)) {
    const blockingDueDate = getNanopayBlockDueDate(featureFlags);
    header = 'BLOCK_PAYMENTS_NANOPAY_BANK_HEADER_DUE_DATE';
    text = 'BLOCK_PAYMENTS_DUE_DATE';
    const localeTimeString = gettoLocaleTimeString(blockingDueDate);
    messageValues = { dueTime: localeTimeString };

    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        isBlockWithDueDate: true,
        blockingDueDate,
        localeTimeString,
        source: 'bank nanopay',
      },
    });
  }
  const paymentType = isDateScheduled ? 'schedule' : isAutoPayOn ? 'autopay' : 'payment';

  const activityInfo = { paymentType, paymentMethod: paymentMethodType, screen: 'payable' };

  return text ? (
    <MessageCard
      activityInfo={activityInfo}
      header={header}
      text={text}
      status={status}
      messageValues={messageValues}
    />
  ) : null;
};
